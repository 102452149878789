<template>
  <el-card id="creatCompanyService">
    <header>
      <h2>公司管理--内部</h2>
      <div class="search">
        <div class="left">
          <div class="left-wrap">
            <span>企业ID</span>
            <el-input v-model="id" placeholder="企业ID" size="mini"></el-input>
          </div>
          <div class="left-wrap">
            <span>企业</span>
            <el-input v-model="realnameSearch" placeholder="请输入企业名称" size="mini"></el-input>
          </div>
          <div class="left-wrap">
            <span>账号</span>
            <el-input v-model="usernameSearch" placeholder="请输入账号" size="mini"></el-input>
          </div>
          <div class="left-wrap">
            <span>客服</span>
            <el-input v-model="operators" placeholder="客服人员" size="mini"></el-input>
          </div>
          <div class="left-wrap">
            <span>业务员</span>
            <el-select v-model="salesman" placeholder="请选择业务员" size="mini" class="reset-width">
              <el-option v-for="item in salesmanList" :key="item.id" :label="item.lable" size="mini" :value="item.lable"
                class="reset-width">
              </el-option>
            </el-select>
          </div>
          <div class="left-wrap">
            <span>锁定状态</span>
            <el-select v-model="value" placeholder="请选择锁定状态" size="mini">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="right">
          <el-button type="primary" icon="el-icon-search" @click="handleSearch" size="mini"
            class="searchBtn">查找</el-button>

          <el-button type="primary" icon="el-icon-refresh" @click="reset()" size="mini" class="reset">重置</el-button>
        </div>
      </div>
    </header>
    <hr>
    <main>
      <el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
        height="600">
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column prop="id" label="企业ID" align="center"></el-table-column>
        <el-table-column prop="realname" label="企业名称" align="center"></el-table-column>
        <el-table-column prop="username" label="账号" align="center"></el-table-column>
        <!-- <el-table-column
          prop="id"
          label="ID"
          align="center"
        ></el-table-column> -->
        <el-table-column prop="seatCount" label="坐席额度" align="center"></el-table-column>
        <el-table-column prop="money" label="余额" align="center"></el-table-column>
        <el-table-column prop="seatMoney" label="套餐余额" align="center"></el-table-column>
        <el-table-column prop="rate" label="费率" align="center"></el-table-column>
        <el-table-column prop="salesman" label="业务员" align="center" style="color: red"></el-table-column>
        <el-table-column prop="operators" label="客服人员" align="center"></el-table-column>

        <el-table-column prop="type" label="通道类型" align="center" style="color: red" v-if="role == '客服'">
          <template #default="scope">
            <el-button type="text" disabled size="mini" v-if="scope.row.type == '0'" icon="el-icon-loading"></el-button>
            <span v-if="scope.row.type == 1">移动4</span>
            <span v-if="scope.row.type == 2">电信</span>
            <span v-if="scope.row.type == 3">联通2</span>
            <span v-if="scope.row.type == 4">联通1</span>
            <span v-if="scope.row.type == 5">移动ax</span>
            <span v-if="scope.row.type == 6">联通3</span>
            <span v-if="scope.row.type == 7">联通4</span>
            <span v-if="scope.row.type == 8">H</span>
            <span v-if="scope.row.type == 9">联通5</span>
            <span v-if="scope.row.type == 10">移动3</span>
            <span v-if="scope.row.type == 11">联通6</span>
            <span v-if="scope.row.type == 12">联通7</span>
            <span v-if="scope.row.type == 13">移动5</span>
            <span v-if="scope.row.type == 14">S</span>
            <span v-if="scope.row.type == 15">D</span>
            <span v-if="scope.row.type == 21">2号线</span>
            <span v-if="scope.row.type == 23">新2号线</span>


          </template>
        </el-table-column>

        <el-table-column label="操作" align="center" style="dispaly: flex" width="340">
          <template #default="scope">
            <!-- <el-button
              type="primary"
              size="mini"
              @click="openOperationLog(scope.row)"
              v-if="parentId == 3555 || role == '客服'"
              >操作日志</el-button
            >
            <el-button
              type="primary"
              size="mini"
              @click="update(scope)"
              v-if="parentId == 3555 || role == '客服'"
              >编辑</el-button
            > -->

            <el-button type="primary" size="mini" @click="queryCompanyTake(scope.row)">公司信息记录</el-button>
            <el-button type="primary" size="mini" @click="openEdit(scope.row)">操作</el-button>

            <el-button type="danger" size="mini" @click="lock(scope)"
              v-if="scope.row.status == '2' && parentId != 7">解锁</el-button>
            <el-button type="primary" size="mini" @click="lock(scope)"
              v-if="scope.row.status == '1' && parentId != 7">锁定</el-button>
            <el-button type="danger" size="mini" @click="lock(scope)" v-if="scope.row.status == '2' && parentId == 7"
              disabled>解锁</el-button>
            <el-button type="primary" size="mini" @click="lock(scope)" v-if="scope.row.status == '1' && parentId == 7"
              disabled>锁定</el-button>
            <el-button type="danger" size="mini" @click="refund(scope)"
              v-if="parentId == 3555 || role == '客服'">发起退款</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--分页  -->
      <div class="pagination">
        <el-pagination background layout="total, prev, pager, next,sizes,jumper" :current-page="pageIndex"
          :page-size="pageSize" :page-sizes="[10, 20, 50, 100, 200]" :total="pageTotal" @size-change="handleSizeChange"
          @current-change="handlePageChange"></el-pagination>
      </div>
    </main>

    <!-- 编辑弹出框 -->
    <el-dialog title="编辑公司账号" :visible.sync="updateShow" top="2%" width="30%">
      <el-form ref="form" :model="forms" label-width="100px">
        <el-form-item label="账号">
          <el-input v-model="forms.username" placeholder="请输入账号" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="appid">
          <el-input v-model="forms.appid" placeholder="请输入appid" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input v-model="forms.password" placeholder="请输入密码" show-password="true" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="公司名字">
          <el-input v-model="forms.realname" placeholder="请输入公司名字" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="签约周期" prop="remark">
          <el-input v-model="forms.remark" placeholder="签约周期" size="mini" disabled></el-input>
        </el-form-item>
        <el-form-item label="客服人员" prop="operators">
          <el-input v-model="forms.operators" placeholder="客服人员" size="mini" disabled></el-input>
        </el-form-item>
        <el-form-item label="业务员" prop="salesman">
          <el-select v-model="forms.salesman" placeholder="请选择业务员" size="mini" class="reset-width">
            <el-option v-for="item in salesmanList" :key="item.id" :label="item.lable" size="mini" :value="item.lable"
              class="reset-width">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户等级">
          <el-select v-model="forms.grade" placeholder="请选择客户等级" size="mini" class="reset-width" disabled>
            <el-option v-for="item in gradeList" :key="item.id" :label="item.lable" size="mini" :value="item.id"
              class="reset-width">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订阅类型">
          <el-select v-model="forms.whole" placeholder="请选择订阅类型" size="mini" class="reset-width" disabled>
            <el-option v-for="item in wholeList" :key="item.id" :label="item.lable" size="mini" :value="item.id"
              class="reset-width">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="拨打模式">
          <el-select v-model="forms.ip" placeholder="请选择拨打模式" size="mini" class="reset-width" disabled>
            <el-option v-for="item in ipList" :key="item.id" :label="item.lable" size="mini" :value="item.id"
              class="reset-width">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="坐席额度">
          <el-input-number v-model="forms.seatCount" :precision="1" :step="1" :max="99999999" size="mini"
            style="margin-left: 15px"></el-input-number>
        </el-form-item>
        <!-- <el-form-item label="余额">
          <el-input-number
            v-model="forms.money"
            :precision="1"
            :step="1"
            :max="99999999"
            style="margin-left:15px"
          ></el-input-number>
        </el-form-item> -->
        <el-form-item label="费率">
          <el-input-number v-model="forms.rate" :precision="2" :step="0.1" :max="1000" size="mini"
            style="margin-left: 15px" disabled></el-input-number>
        </el-form-item>
        <el-form-item label="套餐月租">
          <el-input-number v-model="forms.seatRate" :precision="2" :step="0.1" :max="1000" size="mini"
            style="margin-left: 15px" disabled></el-input-number>
        </el-form-item>
        <el-form-item label="套餐外费率">
          <el-input-number v-model="forms.seatRateW" :precision="2" :step="0.1" :max="1000" size="mini"
            style="margin-left: 15px" disabled></el-input-number>
        </el-form-item>
      </el-form>
      <!-- <template #footer>
        <span class="dialog-footer">
          <el-button @click="updateShow = false">取 消</el-button>
          <el-button type="primary" @click="updateSubmit">确 定</el-button>
        </span>
      </template> -->
    </el-dialog>

    <!-- 问题记录弹出框 -->
    <el-dialog title="添加问题记录" :visible.sync="companyTakeShow" top="2%" width="60%">
      <el-form ref="form" :model="forms" label-width="100px">
        <el-form-item label="公司名称">
          <el-input v-model="forms.realname" disabled placeholder="请输入账号" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="新增问题">
          <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6 }" placeholder="请输入问题"
            v-model="currentCompanyTake.question">
          </el-input>
        </el-form-item>
        <!-- <el-form-item label="答案记录">
          <el-input type="textarea" :autosize="{ minRows: 6, maxRows: 6 }" :disabled="!currentCompanyTake.question"
            placeholder="请输入内容" v-model="currentCompanyTake.answer">
          </el-input>
        </el-form-item> -->
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="companyTakeShow = false">取 消</el-button>
          <el-button type="primary" @click="updateSubmitCompanyTake">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 历史充值记录弹出框 -->
    <el-drawer title="历史充值记录" :visible.sync="drawer" :direction="direction">
      <el-table :data="rechargeRecord" style="width: 100%; text-align: center" height="95%">
        <el-table-column prop="money" label="充值后余额"> </el-table-column>
        <el-table-column prop="oldMoney" label="充值前余额"> </el-table-column>
        <el-table-column prop="createTime" label="时间"> </el-table-column>
        <el-table-column prop="remarks" label="备注信息"> </el-table-column>
      </el-table>
      <el-pagination @size-change="handleRechargeSizeChange" @current-change="handleRechargeCurrentChange"
        :current-page="rechargeParameter.pageIndex" :page-size="rechargeParameter.pageSize"
        layout="total, prev, pager, next" :total="rechargeParameter.pageTotal">
      </el-pagination>
    </el-drawer>
    <!-- 发起退款弹出框 -->
    <el-dialog title="发起退款" :visible.sync="refundVisible">
      <el-form ref="addForm" :model="addForm" label-width="100px">
        <div class="refund">
          <el-form-item label="公司名称" prop="workMessage">
            <el-input v-model="addForm.workMessage" placeholder="请输入公司名称" disabled></el-input>
          </el-form-item>
          <el-form-item label="行业类型" prop="askType">
            <el-input v-model="addForm.askType" placeholder="请输入行业类型"></el-input>
          </el-form-item>
          <el-form-item label="开户时间" prop="beginTime">
            <el-date-picker v-model="addForm.beginTime" type="datetime" disabled placeholder="选择开户时间">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="套餐" prop="endTime">
            <el-input v-model="addForm.endTime" placeholder="套餐" disabled></el-input>
          </el-form-item>
          <el-form-item label="费率" prop="remark">
            <el-input v-model="addForm.remark" placeholder="费率" disabled></el-input>
          </el-form-item>
          <el-form-item label="是否开票" prop="remark1">
            <el-select v-model="addForm.remark1" placeholder="请选择" filterable>
              <el-option v-for="item in TFaskTypeList" :key="item.id" :label="item.title" :value="item.title">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="坐席额度" prop="askTime">
            <el-input v-model="addForm.askTime" placeholder="坐席额度" disabled></el-input>
          </el-form-item>
        </div>
        <div class="balance">
          <el-form-item label="余额" prop="remark4">
            <el-input v-model="addForm.remark4" placeholder="余额" disabled></el-input>
          </el-form-item>
          <el-button @click="mqyspyy" type="primary" style="margin-bottom: 22px; margin-left: 10px">充值记录</el-button>
        </div>

        <el-form-item label="退款原因" prop="remark3">
          <el-input type="textarea" rows="2" v-model="addForm.remark3" placeholder="请输入退款原因"></el-input>
        </el-form-item>
        <!-- <el-form-item label="附件" prop="workImg">
          <el-upload
            :limit="1"
            class="upload-demo"
            :headers="uploadHeaders"
            action="api/workOrder/upload"
            :file-list="addForm.workImg"
            :on-success="handleAvatarSuccess"
            list-type="picture"
          >
            <el-button size="mini" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item> -->
      </el-form>
      <template #footer>
        <span class="dialog-footer mqyzs">
          <el-button @click="refundVisible = false">取 消</el-button>
          <el-button type="primary" @click="refundSubmit">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 操作日志抽屉 -->
    <el-drawer title="操作日志列表" :visible.sync="operationLogdrawer" :direction="direction">
      <div style="padding: 10px 20px; box-sizing: border-box">
        <el-table :data="operationLogArr" border style="width: 100%">
          <el-table-column v-for="(v, i) in operationLogArrLabel" :key="i" :prop="v.name" :label="v.label" align="center">
          </el-table-column>
        </el-table>
      </div>
    </el-drawer>
    <!-- 操作弹框 -->
    <el-dialog title="公司设置" :visible.sync="editVisible">
      <div class="edit-box">
        <el-button @click="openOperationLog(currentScopeRow)" size="medium" style="margin-bottom: 20px"
          v-if="parentId == 3555 || role == '客服'">
          操作日志<i class="el-icon-arrow-right el-icon--right"></i></el-button>
        <el-button size="medium" style="margin-bottom: 20px" @click="update(currentScopeRow)"
          v-if="parentId == 3555 || role == '客服'">
          编辑<i class="el-icon-arrow-right el-icon--right"></i></el-button>
        <!-- <el-button
          size="medium"
          style="margin-bottom: 20px"
          @click="queryCompanyTake(currentScopeRow)"
          v-if="parentId == 3555 || role == '客服'"
        >
          查看问题记录<i class="el-icon-arrow-right el-icon--right"></i
        ></el-button> -->
        <el-button @click="todzwl(currentScopeRow)" size="medium" style="margin-bottom: 20px">设置地理围栏<i
            class="el-icon-arrow-right el-icon--right"> </i></el-button>
      </div>
    </el-dialog>

    <!-- 退出电子围栏按钮 -->
    <el-button v-if="openDzwl" class="quit-btn" @click="quitDzwl">退出地理围栏</el-button>
    <!-- 电子围栏 -->
    <dzwl v-if="openDzwl" @getOpenDzwl="getOpenDzwl" :companyName="currentCompany"></dzwl>
  </el-card>
</template>

<script>
// 导入电子围栏
import dzwl from "@/views/dzwl/dzwl";
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import {
  userUpdate,
  userOperatorsFindlist,
  userFindAppidbytype,
  operationLogJournal,
} from "@/api/user";

import {
  customerQuestionList,
  customerQuestionAdd,
  customerQuestionUpdate,
} from "@/api/company_renew";
import { approverAdd } from "@/api/approver";
import { logUserList } from "@/api/log";
export default {
  components: {
    dzwl,
  },
  data() {
    return {
      salesmanList: [
        {
          id: 0,
          lable: "李天豪-总经办",
        },
        {
          id: 1,
          lable: "沈涛-骑士团",
        }, {
          id: 2,
          lable: "杨鹏-骑士团",
        }, {
          id: 3,
          lable: "邓昊燃-骑士团",
        }, {
          id: 4,
          lable: "林靖-骑士团",
        }, {
          id: 5,
          lable: "都文豪-骑士团",
        }, {
          id: 6,
          lable: "施陈龙-骑士团",
        }, {
          id: 7,
          lable: "蒋发-骑士团",
        }, {
          id: 7.1,
          lable: "王芊驹-骑士团",
        }, {
          id: 7.2,
          lable: "陈思-骑士团",
        },  {
          id: 8,
          lable: "刘瑶-飞跃队",
        }, {
          id: 9,
          lable: "谭淼-飞跃队",
        }, {
          id: 10,
          lable: "赵洋-飞跃队",
        }, {
          id: 11,
          lable: "曹月-飞跃队",
        }, {
          id: 12,
          lable: "于源琦-飞跃队",
        },
        {
          id: 12.1,
          lable: "王昱盛-飞跃队",
        },  {
          id: 12.2,
          lable: "陈睿-飞跃队",
        },
        {
          id: 13,
          lable: "paas-芯霓",
        },

        {
          id: 14,
          lable: "上海宏佳",
        },
      ],
      currentCompanyTake: {},
      currentCompany: null,
      openDzwl: false,
      editVisible: false,
      currentScopeRow: null,
      direction: "rtl",
      operationLogdrawer: false,
      operationLogArrLabel: [
        {
          name: "userName",
          label: "操作人",
        },
        {
          name: "createDate",
          label: "操作时间",
        },
        {
          name: "remarke",
          label: "操作详细信息",
        },
        {
          name: "executeTimeMills",
          label: "耗时时间",
        },
      ],
      operationLogArr: [],
      role: null,
      direction: "rtl",
      imgUrl: "",
      uploadHeaders: {
        "X-Access-Token": localStorage.getItem("TOKEN"),
      }, // 请求头携带token值
      addForm: {
        workImg: [],
      },
      TFaskTypeList: [
        {
          id: 0,
          title: "开发票",
        },
        {
          id: 1,
          title: "不开发票",
        },
      ],
      refundVisible: false,
      custom_stopReason: null,
      activeName: "updata",
      updateNoteVisible: false,
      addNoteVisible: false,
      addNoteSVisible: false,
      rechargeRecord: [],
      rechargeParameter: {
        pageTotal: 0, //页数
        pageIndex: 1, //当前在第几页
        pageSize: 10, //前端规定每页显示的数量
      },
      // 备注弹出框
      causeVisble: false,
      // direction: "ttb",
      // 历史充值记录弹出框
      drawer: false,
      parentId: null,
      gradeList: [],
      wholeList: [],
      ipList: [],
      id: null,
      type: null,
      realnameSearch: null,
      usernameSearch: null,
      operators: null,
      salesman: null,
      updateShow: false,
      companyTakeShow: false,
      remarks: null,
      value: null,
      form: {
        seatMoney: "",
        username: "",
        password: "",
        appid: "",
        realname: "",
        money: 0,
        ip: "",
        remark: "",
        seatCount: "",
        oldMoney: "",
        operators: "",
        salesman: "",
        whole: "",
        rate: "",
        seatRate: "",
        seatRateW: "",
        stopReason: "",
      },
      forms: {},
      seatMoney: null,
      password: null,
      tableData: [], //表格内绑定的数据
      pageTotal: 0, //页数
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示的数量
      page: 1, //第几页
      size: 10, //每页的数量
      realname: null,
      channelType: "", //通道类型
      options: [
        {
          value: "1",
          label: "未锁定",
        },
        {
          value: "2",
          label: "已锁定",
        },
      ],
      value: "",
    };
  },
  created() {
    this.realname = localStorage.getItem("realname");
    this.parentId = localStorage.getItem("parentId");
    this.role = localStorage.getItem("role");
    this.getList();
  },
  methods: {
    // 跳转到地理围栏
    todzwl(row) {
      this.editVisible = false;
      console.log("跳转到地理围栏", row);
      this.currentCompany = row;
      this.openDzwl = true;
    },
    // 提交问题记录
    async updateSubmitCompanyTake() {
      let params;
      let res;
      if (!!this.currentCompanyTake) {
        params = {
          parentId: this.forms.id,
          ...this.currentCompanyTake,
        };
        res = await customerQuestionAdd(params);
      } else {
        params = { id: this.currentCompanyTake.name, answer: this.currentCompanyTake.question };
        res = await customerQuestionUpdate(params);
      }
      if (res.data.statusCode == "00000") {
        Message.success(res.data.message);
        this.companyTakeShow = false;
        this.currentCompanyTake = {}
      } else {
        Message.error(res.data.message);
      }
    },
    // 退出地理围栏
    quitDzwl() {
      this.openDzwl = false;
    },
    // 地理围栏关闭(组件控制)
    getOpenDzwl(bool) {
      this.getList();
      this.openDzwl = bool;
    },
    // 打开操作弹框
    openEdit(row) {
      this.currentScopeRow = row;
      console.log("asdasd", row);
      this.editVisible = true;
    },
    async openOperationLog(row) {
      let params = {
        companyId: row.id,
      };

      let res = await operationLogJournal(params);
      console.log("公司操作信息", res);

      this.operationLogArr = res.data.data;
      this.operationLogdrawer = true;
    },
    // 历史充值记录弹出框事件
    mqyspyy() {
      let params = {
        page: this.rechargeParameter.pageIndex,
        remarks: "充值金额",
        id: this.forms.id,
      };
      let res = logUserList(params);
      res.then((res) => {
        let data = res.data.data;
        this.rechargeRecord = data;
        this.rechargeParameter.pageTotal = res.data.total;
      });
      this.drawer = true;
    },
    handleRechargeSizeChange(val) {
      this.mqyspyy();
      console.log(`每页 ${val} 条`);
    },
    handleRechargeCurrentChange(val) {
      this.rechargeParameter.pageIndex = val;
      this.mqyspyy();
    },
    // 转换日期
    getYMDHMS(format, date) {
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
    // 退费按钮
    refund(row) {
      console.log(1234, row.row);
      this.addForm.remark4 = row.row.money;
      this.addForm.remark = row.row.rate;
      this.addForm.askTime = row.row.seatCount;
      this.addForm.endTime = row.row.seatMoney;
      this.addForm.workMessage = row.row.realname;
      this.returnPremiumId = row.row.id;
      this.refundVisible = true;
      this.addForm.beginTime = row.row.createTime;
    },
    // 退费确认
    refundSubmit() {
      let params = {
        workOrderType: 5,
        returnPremiumId: this.returnPremiumId,
        workMessage: this.addForm.workMessage, // 公司名称
        askType: this.addForm.askType, // 行业类型
        endTime: this.addForm.endTime, // 套餐
        remark: this.addForm.remark, // 费率
        remark1: this.addForm.remark1, // 是否开票
        askTime: this.addForm.askTime, // 坐席额度
        remark3: this.addForm.remark3, // 退款原因
        remark4: this.addForm.remark4, // 余额
        beginTime: this.addForm.beginTime
          ? this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.addForm.beginTime)
          : null, // 开户时间
      };
      // console.log(2345567, params);
      let res = approverAdd(params);
      res.then((res) => {
        let code = res.data.statusCode;
        if (code == "00000") {
          Message.success(res.data);
        } else {
          Message.error(res.data);
        }
      });
    },
    // 分页
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    //编辑确认
    updateSubmit() {
      let params = {
        username: this.forms.username,
        appid: this.forms.appid,
        password: this.forms.password,
        realname: this.forms.realname,
        grade: this.forms.grade,
        remark: this.forms.remark, //签约周期
        whole: this.forms.whole,
        seatCount: this.forms.seatCount,
        operators: this.forms.operators,
        salesman: this.forms.salesman,
        rate: this.forms.rate,
        seatRate: this.forms.seatRate,
        seatRateW: this.forms.seatRateW,
        id: this.forms.id,
        ip: this.forms.ip,
      };
      let res = userUpdate(params);
      res.then((res) => {
        if (res.data.statusCode == "00000") {
          Message.success(res.data.message);
          this.updateShow = false;
          this.getList();
        } else {
          Message.error(res.data.message);
          this.updateShow = false;
          this.getList();
        }
      });
    },
    // 查看问题记录
    async queryCompanyTake(row) {
      this.forms = row;
      console.log("row", row);
      // let params = {
      //   parentId: row.id,
      // };
      // let res = await customerQuestionList(params);
      // console.log("resasdasd", res);
      // if (res.data.data.length) {
      //   this.currentCompanyTake = res.data.data[0];
      // } else {
      //   this.currentCompanyTake = {}
      // }
      // console.log("currentCompanyTake", this.currentCompanyTake);

      this.companyTakeShow = true;
    },
    //编辑按钮
    update(row) {
      // this.updateShow = true;
      // this.forms = row;
      this.updateShow = true;
      this.forms.username = row.username;
      this.forms.appid = row.appid;
      // this.forms.password = row.password;
      this.forms.password = null;
      this.forms.realname = row.realname;
      this.forms.grade = row.grade;
      this.forms.remark = row.remark;
      this.forms.whole = row.whole;
      this.forms.seatCount = row.seatCount;
      this.forms.operators = row.operators;
      this.forms.salesman = row.salesman;
      this.forms.rate = row.rate;
      this.forms.seatRate = row.seatRate;
      this.forms.seatRateW = row.seatRateW;
      this.forms.id = row.id;
      this.forms.ip = row.ip;
      this.forms.seatMoney = row.seatMoney;
    },
    //重置按钮
    reset() {
      this.usernameSearch = null;
      this.realnameSearch = null;
      this.operators = null;
      this.salesman = null;
      this.value = null;
      (this.id = null), this.getList();
    },
    // 展示
    async getList() {
      let params = {
        id: this.id,
        page: this.page,
        size: this.size,
        username: this.usernameSearch,
        realname: this.realnameSearch,
        operators: this.operators,
        salesman: this.salesman,
        parentId: 0,
        status: this.value ? this.value : null,
        stopReason: this.stopReason,
      };
      let res = await userOperatorsFindlist(params);
      if (res.data.statusCode == null) {
        this.tableData = res.data.data;
        this.pageTotal = res.data.total;

        //循环每一个数据
        let ip0Arr = []
        let ip1Arr = []
        this.tableData.forEach((v) => {
          if (v.ip == 0) {
            ip0Arr.push(v.appid)
          } else {
            ip1Arr.push(v.appid)
          }
        })
        let resp0 = {}
        if (ip0Arr.length != 0) {
          resp0 = await userFindAppidbytype({
            ip: 0,
            listAppid: ip0Arr
          });
          resp0 = res.data.data
        }
        let resp1 = {}
        if (ip1Arr.length != 0) {
          res = await userFindAppidbytype({
            ip: 1,
            listAppid: ip1Arr
          });
          resp1 = res.data.data
        }

        console.log("ip0Arr", resp0);
        console.log("ip1Arr", resp1);
        const newObj = Object.assign(resp1, resp0)

        Object.keys(newObj).forEach((key) => {
          this.tableData.forEach((v) => {
            if (v.appid == key + '') {
              v.type = newObj[key]
            }
          })
        })

        //循环每一个数据
        // for (var i = 0; i < this.tableData.length; i++) {
        //   let params = {
        //     ip: this.tableData[i].ip,
        //     appid: this.tableData[i].appid,
        //   };
        //   let index = i;
        //   let res = userFindAppidbytype(params);
        //   res.then((resp) => {
        //     this.tableData[index].type = resp.data.data;
        //   });
        // }
      } else {
        Message.error(res.data.message);
      }
    },
    // 解锁/锁定
    lock(row) {
      this.$confirm("是否确认该操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          let params;
          if (row.row.status == 2) {
            params = {
              id: row.row.id,
              status: 1,
            };
          } else {
            params = {
              id: row.row.id,
              status: 2,
            };
          }
          let res = userUpdate(params);
          res.then((res) => {
            if (res.data.statusCode == "00000") {
              Message.success(res.data.message);
              this.getList();
            } else {
              Message.error(res.data.message);
              this.getList();
            }
          });
        })
        .catch(() => {
          Message.info("已取消操作");
        });
    },
    // 搜索
    handleSearch() {
      this.page = 1;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.edit-box {
  display: flex;
  // justify-content: space-between;
  flex-wrap: wrap;

  .el-button {
    margin-left: 0;
    margin-right: 16px;

    span {
      display: flex;
      justify-content: space-between;
      width: 170px;
    }
  }
}

#creatCompanyService {
  .quit-btn {
    position: fixed;
    z-index: 4;
    top: 18px;
    right: 400px;
  }

  header {
    h1 {
      font-weight: normal;
    }

    .search {
      display: flex;
      margin-top: 20px;
      flex-direction: column;

      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        line-height: 30px;

        .el-select {
          width: 100%;
        }

        .left-wrap {
          width: 20%;
          display: flex;
          align-items: center;

          span {
            margin: 10px;
            width: 70px;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }

      .right {
        margin-top: 20px;
        text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }

  main {
    .pagination {
      text-align: right;
      margin: 20px 0;
    }
  }

  .recharge-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .refund {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto;
    grid-gap: 10px;
  }

  .balance {
    display: flex;
    align-items: center;
  }

  /deep/.el-drawer {
    width: 45% !important;

    .el-drawer__header {
      font-size: 18px;
    }

    .skbContent {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      padding: 10px 10px;
      box-sizing: border-box;

      .tclist {
        color: #606266;
        font-size: 16px;
        display: flex;
        padding-bottom: 14px;
        box-sizing: border-box;
      }

      .nullTitle {
        font-size: 20px;
        text-align: center;
        font-weight: bold;
        line-height: 400px;
      }
    }
  }
}
</style>